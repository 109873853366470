<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
      <strong style="color: red; padding: 0 24px;">温馨提示：该页面仅作展示，功能正在开发中!</strong>
    </template>

    <!-- 搜索栏 -->
    <template v-slot:handles-right>
      <a-form-model layout="inline"
        :form="searchForm"
        @submit="handleSearchSubmit"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input style="width: 260px" v-model="searchForm.keyName" placeholder="请输入企业名称/营业执照"></a-input>
        </a-form-model-item>
        <a-form-model-item style="margin-right: 0">    
          <a-button class="mp-grey-btn" html-type="submit">
            查询
          </a-button>
          <a-button class="mp-grey-btn" style="margin-left: 8px" @click="resetSearchForm">
            重置
          </a-button>
        </a-form-model-item>    
      </a-form-model>
    </template>

    <!-- 列表 -->
    <div class="mp-table-wrap">
      <a-table :columns="columns" 
        :data-source="tableData" 
        rowKey="id"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >   
          <div slot="serial" slot-scope="text,record,index">{{index+1}}</div>
          <div slot="handles" slot-scope="text, record">
            <a-button type="link" size="small" @click="toDetail(record)">查看详情</a-button>
            <a-button type="link" size="small">短信提醒</a-button>
          </div>
      </a-table>
    </div>
  </page-head-wrapper>
</template>

<script>
const columns = [
  {
    align: 'center',
    title: '序号',
    width: '80px',
    scopedSlots: { customRender: 'serial'}
  },
  {
    align: 'center',
    title: '企业名称',
    dataIndex: 'companyName'
  },
  {
    align: 'center',
    title: '企业类型',
    dataIndex: 'companyType',
  },
  {
    align: 'center',
    title: '营业执照',
    dataIndex: 'companyCode',
  },
  {
    align: 'center',
    title: '公司地址',
    dataIndex: 'companyAddress',
  },
  {
    align: 'center',
    title: '联系人员',
    dataIndex: 'companyContact'
  },
  {
    align: 'center',
    title: '联系电话',
    dataIndex: 'companyPhone'
  },
  {
    align: 'center',
    title: '相关操作',
    width: 160,
    scopedSlots: { customRender: 'handles'}
  },
]
export default {
  name: 'static-data-company-incomplete-list',
  keep_alive_tab: true,
  data() {
    return {
      // 搜索表单
      searchForm: {
        keyName: '',
      },
       
      // 列表
      tableData: [
        {
          id: '1',
          companyName: '杭州衣裳股份有限公司',
          companyType: '个人型',
          companyCode: '-',
          companyAddress: '杭州市临平区玩月街',
          companyContact: '张三',
          companyPhone: '17334566544',
        },
        {
          id: '2',
          companyName: '杭州一三股份有限公司',
          companyType: '工商型',
          companyCode: '66436545345454',
          companyAddress: '-',
          companyContact: '李四',
          companyPhone: '18311112222',
        },
        {
          id: '3',
          companyName: '杭州hello股份有限公司',
          companyType: '-',
          companyCode: '-',
          companyAddress: '-',
          companyContact: '-',
          companyPhone: '-',
        }
      ],
      columns,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true
      },
    }
  },
  methods: {
    // 搜索表单
    handleSearchSubmit() {
      console.log(this.searchForm)
      // this.pagination.current = 1
      // this.getListData()
    },
    // 重置搜索
    resetSearchForm() {
      console.log('resetSearchForm')
      // this.pagination.current = 1
      // this.searchForm.keyName = ''
      // this.searchForm.applicationCompany = ''
      // this.searchForm.applicationState = ''
      // this.getListData()
    },
    handleTableChange() {},
    toDetail(record) {
      this.pushTabPage({
        path: '/static-data/company/workbench/incomplete-detail',
        startIndex: 3
      })
    },
    // 刷新操作
    onRefresh() {},
  }
}
</script>